export function getCSSVar<Key extends string>(key: Key): `var(--${Key})` {
	return `var(--${key})`;
}

export function formatCurrency(
	number: number | null,
	includeCents = false
): string {
	const numberToFormat = number ?? 0;

	return numberToFormat
		.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
			minimumFractionDigits: includeCents ? 2 : 0,
			maximumFractionDigits: includeCents ? 2 : 0
		})
		.replace(/\s/g, ' ');
}

export function copyObject<T extends Record<string, any>>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}

export function grammarInflection(
	value: number,
	singular: string,
	plural: string
): string {
	return value === 1 ? singular : plural;
}

export function capitalize(value: string): string {
	return value.charAt(0).toUpperCase() + value.slice(1);
}

export function isEmpty(value: Record<string, any> | any[]): boolean {
	return Array.isArray(value)
		? value.length === 0
		: Object.keys(value).length === 0;
}

export function isEqual(a: any, b: any): boolean {
	if (typeof a !== typeof b) return false;

	if (typeof a !== 'object' || a === null) return a === b;

	if (Array.isArray(a) && Array.isArray(b)) {
		if (a.length !== b.length) return false;

		return a.every((item, index) => isEqual(item, b[index]));
	}

	const keysA = Object.keys(a);
	const keysB = Object.keys(b);

	if (keysA.length !== keysB.length) return false;

	return keysA.every(key => isEqual(a[key], b[key]));
}

export function normalizeString(str = '', avoidChars: string[] = []) {
	const avoidCharsString = avoidChars.join('');
	return str?.trim()
		? str
				.toLowerCase()
				.normalize('NFD')
				.replace(/\p{Diacritic}/gu, '')
				.replace(new RegExp(`[^a-z0-9${avoidCharsString}]`, 'gim'), '')
		: '';
}

export function snakeToCamelCase(str: string): string {
	return str
		.toLowerCase()
		.replace(/([_][a-z])/g, group => group.toUpperCase().replace('_', ''));
}

export function fixEscapedLineBreaks(str: string): string {
	return str.replace(/\\n/g, '\n');
}

export function toKebabCase(str: string): string {
	return str
		.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
		.toLowerCase()
		.replace(/[_\s]+/g, '-');
}

export function slugify(str: string): string {
	return normalizeString(str, [' ', '-'])
		.replace(/(\s|-)+/g, '-')
		.replace(/^-|-$/g, '')
		.toLowerCase();
}

export function diffObjects<T extends Record<string, any>>(
	oldObject: T,
	newObject: T
): Partial<T> {
	const diff: Partial<T> = {};

	for (const key in newObject) {
		if (!isEqual(oldObject[key], newObject[key])) {
			diff[key] = newObject[key];
		}
	}

	return diff;
}

type RemoveUndefinedValuesFromObjectOptions = {
	deep?: boolean;
};

export function removeUndefinedValuesFromObject<
	T extends Record<string, any> | null | undefined
>(object: T, options?: RemoveUndefinedValuesFromObjectOptions): T {
	if (!object || isEmpty(object)) return object;

	const keys = Object.keys(object);

	const result = keys.reduce(
		(acc, key) => {
			const value = object[key];

			if (value === undefined) return acc;

			if (options?.deep && typeof value === 'object' && !Array.isArray(value)) {
				acc[key] = removeUndefinedValuesFromObject(value, options);
			} else {
				acc[key] = value;
			}

			return acc;
		},
		{} as Record<string, any>
	);

	return result as T;
}

export function removeDuplicates<T>(array: T[]): T[] {
	return array.filter((item, index) => array.indexOf(item) === index);
}

export function removeDuplicateBlankSpaces(text: string): string {
	return text.replace(/  +/g, ' ');
}

export function parseBoolean(value: any): boolean | undefined {
	if (value === undefined) return value;

	if (typeof value === 'string') {
		if (value.toLowerCase() === 'true') return true;
		if (value.toLowerCase() === 'false') return false;
	}

	return Boolean(value);
}
